
import Lenis from "@studio-freight/lenis";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const main = document.querySelector(".l-wrapper");
const content = document.querySelector(".scroll-content");

let isTrackPadId = 0;
let isTrackPad = false;
let deltaXArray = [];
var timer_ = null;
let initialHash = false;
let animationend = false;
let keyControl = false;

main.addEventListener('wheel', (e) => {
  // e.preventDefault();
  if (timer_ === null) {
    deltaXArray = [];
  }
  // console.log(e.deltaY);
  deltaXArray.push(e.deltaX);
  if (timer_ !== null) {
    clearTimeout(timer_);
  }
  timer_ = setTimeout(function () {
    isTrackPad = false;
    timer_ = null;
    for (let i = 0; i < deltaXArray.length; i++) {
      if (deltaXArray[i] !== 0) {
        isTrackPad = true;
      }
    }
    // console.log(isTrackPad?"trackpad":"mouse");
    isTrackPadId ++;
  }, 35);
},false);

/**
 * lenis設定
 */
const lenis = new Lenis({
  wrapper: main,
  content: content,
});
function raf(time) {
  lenis.raf(time);
  requestAnimationFrame(raf);
}
requestAnimationFrame(raf);
lenis.stop();
document.querySelector(".index-kv__shape").addEventListener("animationend", (e) => {
  if (e.animationName == "toSquare") {
    // console.log(e.animationName,"アニメーション終了");
    lenis.start();
    animationend = true;
  }
});


/**
 * gsap設定
 */
gsap.registerPlugin(ScrollTrigger);

let inSwiperZone = false;
let card_index = 0;


/**
 * swiperに着いた時の判定
 */

const mediaQueryList = window.matchMedia('(min-width: 768px)');

/**
 * イベントリスナー
 */
const listener = (event) => {
  // リサイズ時に行う処理
  if (event.matches) {
    // 768px以上
    lenis.on("scroll", (e) => {
      // console.log(e);
      // console.log(initialHash);
      if (!initialHash) {
        if (
          main.scrollTop > (document.querySelector(".index-kv").clientHeight + document.querySelector(".index-aboutus").clientHeight) &&
          document.querySelector(".index-businessItems__inner").dataset.index == 0
        ) {
          main.scrollTo(0, (document.querySelector(".index-kv").clientHeight + document.querySelector(".index-aboutus").clientHeight));
          lenis.stop();
          inSwiperZone = true;
          document.querySelector('.float-menu[href="#business"] span').dispatchEvent( new Event("click"));
        }
        if (
          main.scrollTop < (document.querySelector(".index-kv").clientHeight + document.querySelector(".index-aboutus").clientHeight) &&
          document.querySelector(".index-businessItems__inner").dataset.index == 3
        ) {
          main.scrollTo(0, (document.querySelector(".index-kv").clientHeight + document.querySelector(".index-aboutus").clientHeight));
          lenis.stop();
          inSwiperZone = true;
          document.querySelector('.float-menu[href="#business"] span').dispatchEvent( new Event("click"));
        }
      }
    });
  } else {
    // 768px未満
  }
};

// リスナー登録
// mediaQueryList.addListener(listener); // @deprecated
mediaQueryList.addEventListener("change", listener);

// 初期化処理
listener(mediaQueryList);



////////////////////////////////////
////hashチェック//////
////////////////////////////////////
let floatMenuArray = ["top","company","business","news_release","mission","recruit"];
// if(window.location.hash) {
//   lenis.start();
//   hashChange();
//   // hash found
// } else {
//   // No hash found
// }
function hashChange(isImmediate = true) {
  lenis.start();
  initialHash = true;
  let hash = window.location.hash.substring(1); //Puts hash in variable, and removes the # character
  if (floatMenuArray.indexOf(hash) !== -1) {
    for (let i = 0; i < floatMenuArray.length; i++) {
      if (hash === floatMenuArray[i]) {
        // let evt = new Event("click");
        // document.querySelector(`.text-sm a[href="#${floatMenuArray[i]}"] span`).dispatchEvent(evt);
        if (["news_release","mission","recruit"].indexOf(hash) !== -1) {
          card_index = 3;
          document.querySelector(".index-businessItems__inner").style="transition: none";
          document.querySelector(".index-businessItems__inner").dataset.index = 3;
          setTimeout(()=>{document.querySelector(".index-businessItems__inner").style="";},0);
        }
        if (["top","company","business"].indexOf(hash) !== -1) {
          card_index = 0;
          document.querySelector(".index-businessItems__inner").style="transition: none";
          document.querySelector(".index-businessItems__inner").dataset.index = 0;
          setTimeout(()=>{document.querySelector(".index-businessItems__inner").style="";},0);
        }
      }
    }
  } else {
    card_index = 3;
    document.querySelector(".index-businessItems__inner").style="transition: none";
    document.querySelector(".index-businessItems__inner").dataset.index = 3;
    setTimeout(()=>{document.querySelector(".index-businessItems__inner").style="";},0);
  }
  if (!isImmediate && hash == "business") {
    document.querySelector("body").style.pointerEvents = "none";
  }
  lenis.scrollTo(`#${hash}`,{
    immediate: isImmediate,
    onComplete: () => {
      initialHash = false;
      document.querySelector("body").style.pointerEvents = "";
    }
  });
}


////////////////////////////////////
////アンカーリンク//////
////////////////////////////////////
document.querySelectorAll(".anchor-link").forEach((element) => {
  element.addEventListener("click", (e) => {
    e.preventDefault();
    let newHash = element.getAttribute("href").substring(1);
    history.replaceState(null, null, document.location.pathname + '#' + newHash);
    hashChange(false);
  });
});

document.querySelectorAll(".float-menu").forEach((element) => {
  element.addEventListener("click", (e) => {
    e.preventDefault();
    let newHash = element.getAttribute("href").substring(1);
    history.replaceState(null, null, document.location.pathname + '#' + newHash);
    hashChange(false);
  });
});
/**
 * マウスwheelの判定：
 * scroll終わって500ms経ったら一回とカウントされる
 * 一回のscroll始まったら、一回swiperカードの移動処理が走る
 */
var timer = null;
main.addEventListener(
  "wheel",
  function (e) {
    // console.log(e);
    if (isTrackPad) {
      /**
       * 共通の処理：swiperカードの移動
       */
      setList(e.deltaY > 0, e.deltaY <= 0);
    } else {
      if (timer === null) {
        /**
         * 共通の処理：swiperカードの移動
         */
        setList(e.deltaY > 0, e.deltaY <= 0);
      }
      if (timer !== null) {
        clearTimeout(timer);
      }
      timer = setTimeout(function () {
        timer = null;
      }, 350);
    }
  },
  false,
);

/*
 * スワイプイベント設定
 */
function setSwipe(elem) {
  let t = document.querySelector(elem);
  let startX; // タッチ開始 x座標
  let startY; // タッチ開始 y座標
  let moveX; // スワイプ中の x座標
  let moveY; // スワイプ中の y座標
  let dist = 30; // スワイプを感知する最低距離（ピクセル単位）

  // タッチ開始時： xy座標を取得
  t.addEventListener("touchstart", function (e) {
    // e.preventDefault();
    startX = e.touches[0].pageX;
    startY = e.touches[0].pageY;
  });

  // スワイプ中： xy座標を取得
  t.addEventListener("touchmove", function (e) {
    // e.preventDefault();
    moveX = e.changedTouches[0].pageX;
    moveY = e.changedTouches[0].pageY;
  });

  // タッチ終了時： スワイプした距離から上下どちらにスワイプしたかを判定する/距離が短い場合何もしない
  t.addEventListener("touchend", function (e) {
    /**
     * 共通の処理：swiperカードの移動
     */
    setList(startY > moveY && startY > moveY + dist, startY < moveY && startY + dist < moveY);

  });
}
setSwipe("body");

/**
 * 共通の処理：
 * scrollを固定して、swiperカードの移動だけ可能にする
 */
let moveLimitTimeout = null;
let moveLimit = false;
let keyLimitTimeout = null;
let keyLimit = false;
let tempTrackPadId = 0;
function setList (condition1, condition2) {
  if (isTrackPad) {
    if (!moveLimit) {
      if (tempTrackPadId !== isTrackPadId) {
        tempTrackPadId = isTrackPadId;
        if (inSwiperZone) {
          // console.log("新しいscroll");
          condition(condition1, condition2);
          document.querySelector(".index-businessItems__inner").dataset.index = card_index;
        }
        moveLimit = true;
        moveLimitTimeout = setTimeout(function () {
          moveLimit = false;
        }, 500);
      } else {
        if (inSwiperZone) {
          // console.log("新しいscrollではないので、何もしない");
        }
      }

    }
  } else {
    if (inSwiperZone) {
      if (keyControl) {
        if (!keyLimit) {
          keyLimit = true;
          condition(condition1, condition2);
          keyLimitTimeout = setTimeout(function () {
            keyLimit = false;
          }, 500);
        }
      } else {
        condition(condition1, condition2);
      }
      document.querySelector(".index-businessItems__inner").dataset.index = card_index;
    }
  }

}
function condition (condition1, condition2) {
  if (condition1) {
    /**
     * スワイプの場合：下から上にスワイプ
     */
    if (card_index < 3) {
      card_index++;
    } else {
      lenis.start();
      inSwiperZone = false;
    }
  } else if (condition2) {
    /**
     * スワイプの場合：上から下にスワイプ
     */
    if (card_index > 0) {
      card_index--;
    } else {
      lenis.start();
      inSwiperZone = false;
    }
  }
}


////////////////////////////////////
////トップページKVアニメーション//////
////////////////////////////////////

let kv = document.querySelector(".index-kv"),
  indexAboutus = document.querySelector(".index-aboutus"),
  kvShape = document.querySelector(".index-kv__shape"),
  kvImg = document.querySelector(".index-kv__img"),
  kvCircle = document.querySelector(".index-kv__circle"),
  kvTxt = document.querySelector(".index-kv__txt--inner"),
  // kvLink = document.querySelector(".index-kv__link--inner"),
  kvBg = document.querySelector(".index-kv__bg--inner"),
  kvWave = document.querySelector(".index-kv__wave"),
  fvMenu = document.querySelector(".index-fvMenu__inner"),
  business = document.querySelector("#business"),
  news_release = document.querySelector("#news_release"),
  recruit = document.querySelector("#recruit"),
  recruitCircle = document.querySelector(".index-recruit__circle")
  ;


let tl_shrink = gsap.timeline({
  scrollTrigger: {
    scroller: ".l-wrapper",
    trigger: kv,
    end: "bottom 66.6%",
    start: "bottom bottom",
    scrub: true, //スクロールとアニメーション速度リンク
    invalidateOnRefresh: true,
    // markers: true,
  },
});
let tl_shrink2 = gsap.timeline({
  scrollTrigger: {
    scroller: ".l-wrapper",
    trigger: kv,
    end: "bottom 33.3%",
    start: "bottom bottom",
    scrub: true, //スクロールとアニメーション速度リンク
    invalidateOnRefresh: true,
    // markers: true,
  },
});
let tl_shrink3 = gsap.timeline({
  scrollTrigger: {
    scroller: ".l-wrapper",
    trigger: kv,
    end: "bottom 0%",
    start: "bottom bottom",
    scrub: true, //スクロールとアニメーション速度リンク
    invalidateOnRefresh: true,
    // markers: true,
  },
});
// 画像の透明にするアニメーション
tl_shrink.add([
  gsap.to(kvTxt, {
      opacity: 0,
      onComplete: function() {
        this.targets()[0].style.pointerEvents = "none";
      },
      onReverseComplete: function() {
        this.targets()[0].style.pointerEvents = "visible";
      },
    }),
  // gsap.to(kvLink, { opacity: 0 })
]);
// 最初に縮小するアニメーション
tl_shrink2.add([
  gsap.to(kvImg, { opacity: 0 }),
  gsap.to(kvBg, { opacity: 0 }),
  gsap.to(kvShape, { scale: 0.2 }),
  gsap.to(fvMenu, { x: 200})
]);
tl_shrink3.add([
  gsap.to(kvCircle, { opacity: 0 })
]);

let tl_expand = gsap.timeline({
  scrollTrigger: {
    scroller: ".l-wrapper",
    trigger: indexAboutus,
    end: "bottom top",
    start: "top 33.3%",
    scrub: true, //スクロールとアニメーション速度リンク
    invalidateOnRefresh: true,
    // markers: true,
  },
});
// 更に拡大するアニメーション
tl_expand.to(kvShape, { scale: 1162 / 614 });
// 拡大後縮小するアニメーション
tl_expand.to(kvShape, { scale: 1162 / 1228 });
tl_expand.to(kvShape, { scale: 0 });
tl_expand.add([gsap.to(kvWave, { opacity: 0 })]);


let tl_recruit = gsap.timeline({
  scrollTrigger: {
    scroller: ".l-wrapper",
    trigger: recruit,
    // end: "bottom 0%",
    // start: "bottom bottom",
    // scrub: true, //スクロールとアニメーション速度リンク
    invalidateOnRefresh: true,
    // markers: true,
  },
});
tl_recruit.add([
  gsap.fromTo(recruitCircle,{scale:0},{scale:1, duration: 1}),
  // gsap.to(recruitCircle,{
  //   rotate: 360,
  //   duration: 1.5,
  // }),
  gsap.to(recruitCircle,{
    rotate: 360,
    duration: 100,
    repeat: -1, // 無限に繰り返し
  }),
]);

// function onlyChangeHashStyle (newHash) {
//   if (newHash !== 'nohash') {
//     history.replaceState(null, null, document.location.pathname + '#' + newHash);
//     document.querySelector(`.float-menu[href="#${newHash}"] span`).dispatchEvent( new Event("click"));
//   } else {
//     history.replaceState(null, null, document.location.pathname);
//     document.querySelector(`.nohash`).dispatchEvent( new Event("click"));
//   }
// }
// let kv_detect = gsap.timeline({
//   scrollTrigger: {
//     scroller: ".l-wrapper",
//     trigger: kv,
//     end: "bottom center",
//     start: "top 0",
//     invalidateOnRefresh: true,
//     onEnter: () => {
//       // console.log('onEnter');
//       onlyChangeHashStyle('top');
//     },
//     onEnterBack: () => {
//       // console.log('onEnterBack');
//       onlyChangeHashStyle('top');
//     },
//     onLeave: () => {
//       // console.log('onLeave');
//     },
//     onLeaveBack: () => {
//       // console.log('onLeaveBack');
//     },
//   },
// });
// let company_detect = gsap.timeline({
//   scrollTrigger: {
//     scroller: ".l-wrapper",
//     trigger: indexAboutus,
//     end: "bottom center",
//     start: "top center",
//     invalidateOnRefresh: true,
//     onEnter: () => {
//       // console.log('onEnter');
//       onlyChangeHashStyle('company');
//     },
//     onEnterBack: () => {
//       // console.log('onEnterBack');
//       onlyChangeHashStyle('company');
//     },
//     onLeave: () => {
//       // console.log('onLeave');
//     },
//     onLeaveBack: () => {
//       // console.log('onLeaveBack');
//     },
//   },
// });
// let business_detect = gsap.timeline({
//   scrollTrigger: {
//     scroller: ".l-wrapper",
//     trigger: business,
//     end: "bottom center",
//     start: "top center",
//     invalidateOnRefresh: true,
//     onEnter: () => {
//       // console.log('onEnter');
//       onlyChangeHashStyle('business');
//     },
//     onEnterBack: () => {
//       // console.log('onEnterBack');
//       onlyChangeHashStyle('business');
//     },
//     onLeave: () => {
//       // console.log('onLeave');
//       onlyChangeHashStyle('nohash');

//     },
//     onLeaveBack: () => {
//       // console.log('onLeaveBack');
//     },
//   },
// });
// let news_release_detect = gsap.timeline({
//   scrollTrigger: {
//     scroller: ".l-wrapper",
//     trigger: news_release,
//     end: "bottom bottom",
//     start: "top center",
//     invalidateOnRefresh: true,
//     onEnter: () => {
//       // console.log('onEnter');
//       onlyChangeHashStyle('news_release');
//     },
//     onEnterBack: () => {
//       // console.log('onEnterBack');
//       onlyChangeHashStyle('news_release');
//     },
//     onLeave: () => {
//       // console.log('onLeave');
//     },
//     onLeaveBack: () => {
//       // console.log('onLeaveBack');
//     },
//   },
// });
// let recruit_detect = gsap.timeline({
//   scrollTrigger: {
//     scroller: ".l-wrapper",
//     trigger: recruit,
//     end: "bottom center",
//     start: "top center",
//     invalidateOnRefresh: true,
//     onEnter: () => {
//       // console.log('onEnter');
//       onlyChangeHashStyle('recruit');
//     },
//     onEnterBack: () => {
//       // console.log('onEnterBack');
//       onlyChangeHashStyle('recruit');
//     },
//     onLeave: () => {
//       // console.log('onLeave');
//     },
//     onLeaveBack: () => {
//       // console.log('onLeaveBack');
//       onlyChangeHashStyle('nohash');
//     },
//   },
// });








/**
 * key判断
 */
let tempLenisTop = 0;
window.addEventListener(
  "keydown",
  (event) => {
    // event.preventDefault();
    keyControl = true;
    switch (event.key) {
      case "ArrowDown":
        // Do something for "down arrow" key press.
        tempLenisTop = lenis.scroll + 100;
        lenis.scrollTo(tempLenisTop);
        setList(true, false);
        break;
      case "ArrowUp":
        // Do something for "up arrow" key press.
        tempLenisTop = lenis.scroll - 100;
        lenis.scrollTo(tempLenisTop);
        setList(false, true);
        break;
      default:
        return; // Quit when this doesn't handle the key event.
    }

  },
  true,
);
window.addEventListener(
  "keyup",
  (event) => {
    keyControl = false;
  },
  true,
);




////////////////////////////////////
////新着情報＆イベント読み込み//////
////////////////////////////////////
async function fetchAndDisplayData(url, containerId) {
  const res = await fetch(url, { mode: "cors" });
  const data = await res.json();

  // 指定されたコンテナを取得
  const container = document.querySelector(containerId);

  // HTML文字列を作成
  let htmlContent = '';
  data.slice(0, 3).forEach(item => {
    htmlContent += `
      <li class="border-b border-col_border4 pb-[16px] md:pb-[12px] flex flex-col gap-y-[12px] md:gap-y-[4px] overflow-hidden">
        <time class="text-base tracking-[.1em]">${item.date}</time>
        <p>
          <a class="inline-flex items-center" target="_blank" href="${item.url}">
            <span class="text-base tracking-[.1em] leading-[26px] line-clamp-2 md:line-clamp-1">${item.ttl}</span>
            <i class="w-[16px] h-[16px] ml-[4px]"><svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="14.179" height="14.233" viewBox="0 0 14.179 14.233">
          <g transform="translate(-140.91 -439.883)">
            <g transform="translate(140.91 439.883)">
              <g>
                <path d="M11.85,14.233H.6a.6.6,0,0,1-.6-.6V2.617a.6.6,0,0,1,.6-.6H7.2a.6.6,0,0,1,0,1.2h-6v9.816H11.25V7.617a.6.6,0,0,1,1.2,0v6.016A.6.6,0,0,1,11.85,14.233Z"></path>
                <path d="M13.579,6.7a.6.6,0,0,1-.6-.6V1.2h-4.9a.6.6,0,1,1,0-1.2h5.5a.6.6,0,0,1,.6.6V6.1A.6.6,0,0,1,13.579,6.7Z"></path>
                <path d="M0,5.369a.6.6,0,0,1-.434-.186.6.6,0,0,1,.019-.848L4.579-.434a.6.6,0,0,1,.848.019.6.6,0,0,1-.019.848L.414,5.2A.6.6,0,0,1,0,5.369Z" transform="translate(7.607 1.57)"></path>
              </g>
            </g>
          </g>
        </svg></i>
          </a>
        </p>
      </li>
    `;
  });

  // コンテナのinnerHTMLに追加
  container.innerHTML = htmlContent;
}

// イベントデータを取得して表示
fetchAndDisplayData("https://gardenplace.jp/application_apis/get_contents?type=event&key=RbjE2y7TMbsNBCknLA4KNgth", "#gardenplace_event");

// ニュースデータを取得して表示
fetchAndDisplayData("https://gardenplace.jp/application_apis/get_contents?type=newsrelease&key=RbjE2y7TMbsNBCknLA4KNgth", "#gardenplace_news");

// イベントデータを取得して表示
fetchAndDisplayData("https://sapporofactory.jp/application_apis/get_contents?type=event&key=RbjE2y7TMbsNBCknLA4KNgth", "#sapporofactory_event");

// ニュースデータを取得して表示
fetchAndDisplayData("https://sapporofactory.jp/application_apis/get_contents?type=newsrelease&key=RbjE2y7TMbsNBCknLA4KNgth", "#sapporofactory_news");

// イベントデータを取得して表示
fetchAndDisplayData("https://ginzaplace.jp/application_apis/get_contents?type=event&key=RbjE2y7TMbsNBCknLA4KNgth", "#ginzaplace_event");

// ニュースデータを取得して表示
fetchAndDisplayData("https://ginzaplace.jp/application_apis/get_contents?type=newsrelease&key=RbjE2y7TMbsNBCknLA4KNgth", "#ginzaplace_news");
